<template>
  <div class="rays-tuner-screentool-tasklist">
    <a-row class="slider_header" type="flex">
      <a-col :flex="1" class="slider_title"
        >{{ stitle }}
        <div class="count">({{ taskList.length }})</div></a-col
      >
      <a-col flex="40px">
        <a-dropdown overlayClassName="rays-tuner-screen-tool-task-menu-type">
          <a class="ant-dropdown-link" @click.prevent>
            <svg-icon class="icon icon_funnel" icon-class="funnel" />
          </a>
          <template #overlay>
            <a-menu @click="taskListMenuClick">
              <a-menu-item key="1" v-if="taskListType == '2'">
                显示全部
              </a-menu-item>
              <a-menu-item key="2" v-if="taskListType == '1'">
                显示已完成
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </a-col>
    </a-row>
    <div class="slider_item_box" :id="listId" @click="taskListClick">
      <div
        v-for="(item, index) in taskList"
        :key="index"
        class="task_item"
        :class="{ selected: item.selected }"
        :data-taskno="item.params.var_shader_name"
      >
        <div class="task_top">
          <a-tooltip placement="topLeft" :destroyTooltipOnHide="true">
            <template #title>
              <span>{{ item.params.var_shader_name }}</span>
            </template>
            <div class="task_title" :class="item.titleClass">
              变体{{ item.params.var_shader_name }}
            </div>
          </a-tooltip>
          <div class="task_stat" :class="item.statClass">
            {{ item.statStr }}
          </div>
          <a-dropdown overlayClassName="rays-tuner-screen-tool-task-menu">
            <a class="ant-dropdown-link task_more" @click.prevent.stop>
              <svg-icon class="icon task_option" icon-class="more" />
            </a>
            <template #overlay>
              <a-menu @click="taskMenuClick">
                <a-menu-item :key="item.params.var_shader_name">
                  <svg-icon icon-class="delete" class="icon task_del" />
                  删除
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <div class="task_btn">
            <a-tooltip placement="top" :destroyTooltipOnHide="true">
              <template #title>
                <span v-if="item.optTip">{{ item.optTip }}</span>
              </template>
              <svg-icon
                class="icon"
                :icon-class="item.optIcon"
                v-if="item.optTip"
              />
            </a-tooltip>
          </div>
        </div>
        <div class="task_bottom">
          <a-tooltip placement="bottomLeft" :destroyTooltipOnHide="true">
            <template #title>
              <span>{{ item.params.error }}</span>
            </template>
            <div class="task_error">Error : {{ item.params.error }}</div>
          </a-tooltip>
          <a-tooltip placement="bottomLeft" :destroyTooltipOnHide="true">
            <template #title>
              <span>{{ item.params.time }}</span>
            </template>
            <div class="task_time">Time : {{ item.params.time }}</div>
          </a-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  createVNode,
  computed,
  watch,
  nextTick,
} from 'vue';
import PerfectScrollbar from 'perfect-scrollbar';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import store from '@/vuex/store';
import router from '@/routes';
import { message, Modal } from 'ant-design-vue';
import {
  setShaderScreen,
  setShaderHomeInfo,
  setPatDeviceType,
} from '@/models/tunerStorage';
import { debounce } from 'lodash';
import api from '@/api/shader-tools';

export default defineComponent({
  name: 'ScreenTaskList',
  components: {},
  props: {
    stitle: {
      type: String,
      required: true,
    },
    listId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const taskListType = ref('1');
    const $_user = store.state.user.userinfo;
    const $_email = $_user.email;
    // const $_pat = store.state.shaderTool.currentPat;
    let shaderTaskDom = null;
    let shaderTaskPs = null;

    const taskStatStr = [
      '队列中',
      '队列中',
      '工作中',
      '停止中',
      '停止',
      '中断',
      '',
      '已删除',
      '删除中',
    ]; //'暂停'
    const taskStatClass = [
      'queued',
      'queued',
      'working',
      'stoping',
      'stop',
      'stop',
      'finish',
      'tstop',
      'tstop',
    ]; //'pause'
    const taskTitleClass = [
      '',
      '',
      '',
      'tstop',
      'tstop',
      'tstop',
      'tfinish',
      '',
      '',
    ];
    const taskOption = [3, 3, 3, 3, 2, 2, 0, 0, 0]; //'1'
    const taskOptionIcon = ['', 'play', 'redo', 'stop'];
    const taskOptionTip = ['', '开始任务', '重启任务', '停止任务'];

    const curShader = computed(
      () => store.state.shaderTool.currentShader || {}
    );

    // watch(()=>curShader.value.shader_id, () => {
    //     console.log('watch curShader');
    //     getScreenTaskList();
    // });

    const taskUpdate = computed(
      () => store.state.shaderTool.shaderScreen.taskUpdate
    );

    // watch(taskUpdate, () => {
    //     // console.log('watch taskUpdate', shader);
    //     getScreenTaskList();
    // });

    const curScreenTask = computed(
      () => store.state.shaderTool.currentShaderScreenTask
    );

    const curSimplifyTask = computed(
      () => store.state.shaderTool.currentShaderSimplifyTask || { params: {} }
    );

    watch(
      [
        () => curShader.value.shader_id,
        taskUpdate,
        () => curSimplifyTask.value.task_number,
        () => curSimplifyTask.value.params.highlight,
      ],
      () => {
        // console.log('watch taskUpdate', shader);
        getScreenTaskList();
      }
    );

    const taskList = computed(
      () => store.state.shaderTool.screenTaskList || []
    );

    const shaderHomeInfo = computed(
      () => store.state.shaderTool.shaderHome || {}
    );

    // let _testTask = [];
    // for (let i = 0; i < 10; i++) {
    //     let _stat = i - Math.floor(i/8)*8 + 1;
    //     let _option = taskOption[_stat];
    //     _testTask.push({
    //         task_number: "202112062132-" + i,
    //         status: _stat,
    //         statStr:taskStatStr[_stat],
    //         statClass: taskStatClass[_stat],
    //         titleClass : taskTitleClass[_stat],
    //         optIcon: taskOptionIcon[_option],
    //         optTip: taskOptionTip[_option],
    //         params:{
    //             var_shader_name: '_0_'+ i + 'm',
    //             error: i * 0.00001,
    //             time: i * 0.001,
    //             simpl_task_id: "202112062132-" + i,
    //         }
    //     });
    // }
    // store.commit('setScreenTaskList', _testTask);
    // console.log(_testTask);

    const changeTool = (key) => {
      // console.log('changeTool', key);
      const _homeinfo = JSON.parse(JSON.stringify(shaderHomeInfo.value));
      _homeinfo.activeKey = key;
      setShaderHomeInfo(_homeinfo);
      const _path = {
        1: 'simplify',
        2: 'screen',
      };
      router.push({
        path: '/shaderhome/' + _path[key],
      });
    };

    const getScreenTaskList = async (times) => {
      times = times || 0;
      const params = {
        parent_id: curSimplifyTask.value.id || -1,
      };
      const res = await api.taskGetsSub(params);
      if (!res) return;
      if (res.code != 0) {
        store.commit('setShaderScreenPageStat', 0);
        message.error('获取筛选任务失败');
        return;
      }
      const _data = res.data || [];
      if (_data.length == 0) {
        changeTool('1');
      }
      let _page = 0;
      let _loopStat = -1;
      for (let i = 0; i < _data.length; i++) {
        const _stat = _data[i].status;
        const _option = taskOption[_stat];
        _data[i].statStr = taskStatStr[_stat];
        _data[i].statClass = taskStatClass[_stat];
        _data[i].titleClass = taskTitleClass[_stat];
        _data[i].optIcon = taskOptionIcon[_option];
        _data[i].optTip = taskOptionTip[_option];
        _data[i].selected = false;
        _data[i].params = JSON.parse(_data[i].params || '{}') || {};
        if (
          curScreenTask.value &&
          curScreenTask.value.task_number == _data[i].task_number &&
          _data[i].status == 6
        ) {
          _page = 1;
          _data[i].selected = true;
          setPatDeviceType(_data[i].params.device_type);
          setShaderScreen(_data[i]);
        }
        if (
          _data[i].status == 3 ||
          _data[i].status == 8 ||
          _data[i].status == 1
        ) {
          _loopStat = _data[i].status;
        }
      }
      if (times < 10 && (_loopStat == 3 || _loopStat == 8)) {
        setTimeout(() => {
          getScreenTaskList(++times);
        }, 3000);
      }
      // else if (times < 2 && _loopStat == 1) {
      //     setTimeout(()=>{
      //         getScreenTaskList(++times);
      //     }, 3000);
      // }
      store.commit('setShaderScreenPageStat', _page);

      store.commit('setScreenTaskList', _data);
      if (shaderTaskPs && shaderTaskDom) {
        nextTick(() => {
          shaderTaskPs.update();
        });
      }
    };

    const operateAsyncTask = debounce(
      async (task, opt) => {
        // console.log('operateAsyncTask', task_id, opt);
        if (!task.task_number || !opt || opt > 3) return;
        task.optioning = true;
        let _opts = [
          '',
          'stopAsyncTask',
          'deleteAsyncTask',
          'continueAsyncTask',
        ];
        let _strs = ['', '停止', '删除', '重启'];
        let _opt = _opts[opt];
        let _msg = _strs[opt];
        const params = {
          user: $_email,
          task_id: task.task_number,
        };
        const res = await api.operateAsyncTask(_opt, params);
        task.optioning = false;
        if (!res) return;
        if (res.code == 0) {
          getScreenTaskList();
          message.success(_msg + '任务成功！');
        } else {
          message.error(_msg + '任务失败：' + res.detail);
        }
      },
      300,
      { leading: true, trailing: false }
    );

    const delConfirm = (task) => {
      Modal.confirm({
        title: '删除筛选任务',
        icon: createVNode(ExclamationCircleOutlined),
        content:
          '确定要删除该筛选任务吗？（' + task.params.var_shader_name + '）',
        okText: '删除',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise((resolve) => {
            operateAsyncTask(task, 2);
            resolve();
          });
        },
      });
    };

    const taskListMenuClick = (value) => {
      // console.log('taskListMenuClick', value);
      taskListType.value = value.key;
      switch (value.key) {
        case '1': //显示全部
          getScreenTaskList();
          break;
        case '2': {
          //显示已完成
          const _tmplist = JSON.parse(JSON.stringify(taskList.value));
          for (let i = 0; i < _tmplist.length; i++) {
            if (_tmplist[i].status != 6) {
              _tmplist.splice(i, 1);
              i--;
            }
          }
          store.commit('setScreenTaskList', _tmplist);
          if (shaderTaskPs && shaderTaskDom) {
            nextTick(() => {
              shaderTaskPs.update();
            });
          }
          break;
        }
        default:
          break;
      }
    };

    const taskMenuClick = (value) => {
      // console.log('taskMenuClick', value);
      for (let i = 0; i < taskList.value.length; i++) {
        if (taskList.value[i].params.var_shader_name == value.key) {
          let _task = taskList.value[i];
          if (_task.status == 3) {
            message.warning('任务正在停止，请等待。。。');
          } else if (_task.status == 8) {
            message.warning('任务正在删除，请等待。。。');
          } else {
            delConfirm(_task);
          }
          break;
        }
      }
    };

    const taskListClick = (event) => {
      // console.log('taskListClick', event);
      let _item = null;
      let _opt = false;
      let _path = event.target;
      while (_path) {
        // console.log(_path.nodeName, _path.tagName, _path.className,_path.id);
        if (
          (_path.nodeName != 'svg' &&
            _path.tagName != 'use' &&
            _path.className.includes('task_btn')) ||
          (_path.nodeName == 'svg' &&
            _path.className.baseVal.includes('task_option')) ||
          (_path.tagName == 'use' && _path.href.baseVal != '#Icon_more')
        ) {
          _opt = true;
        }
        if (_path.className == 'task_item') {
          _item = _path;
          break;
        }
        if (_path.id == props.listId) {
          break;
        }
        _path = _path.parentNode;
      }
      if (_item) {
        // console.log('_item', _item, _opt, _item.dataset);
        for (let i = 0; i < taskList.value.length; i++) {
          // let _unsel = true;
          taskList.value[i].selected = false;
          if (
            taskList.value[i].params.var_shader_name == _item.dataset.taskno
          ) {
            let _task = taskList.value[i];
            if (!_opt) {
              // console.log('_task', _task);
              _task.selected = true;
              if (_task.status == 6) {
                store.commit('setShaderScreenPageStat', 1);
                setShaderScreen(_task);
                if (shaderHomeInfo.value.activeKey == '1') {
                  changeTool('2');
                }
              } else {
                store.commit('setShaderScreenPageStat', 0);
              }
            } else if (!_task.optioning) {
              //  _unsel = false;
              let _stat = parseInt(_task.status);
              // console.log('opt', _task,_stat);

              switch (_stat) {
                case 0:
                case 1:
                case 2:
                  operateAsyncTask(_task, 1);
                  break;
                case 3:
                  message.warning('任务正在停止，请等待。。。');
                  break;
                case 4:
                case 5:
                  operateAsyncTask(_task, 3);
                  break;
                case 8:
                  message.warning('任务正在删除，请等待。。。');
                  break;
                default:
                  break;
              }
            }
          }
          // if (_unsel) {

          // }
        }
      }
    };

    onMounted(() => {
      // console.log(process.env.NODE_ENV);
      // 初始化PAT列表scroll
      shaderTaskDom = document.querySelector('#' + props.listId);
      shaderTaskPs = new PerfectScrollbar(shaderTaskDom);
    });
    onBeforeUnmount(() => {
      if (shaderTaskPs) {
        shaderTaskPs.destroy();
        shaderTaskPs = null;
      }
      shaderTaskDom = null;
    });

    getScreenTaskList();

    return {
      taskList,
      taskListType,
      taskMenuClick,
      taskListMenuClick,
      taskListClick,
    };
  },
});
</script>

<style scoped lang="scss">
.icon {
  width: 16px;
  height: 16px;
  outline: none;
}

.icon_funnel {
  color: #aaa;
  vertical-align: -2px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
    color: #604bdc;
  }
}
.rays-tuner-screentool-tasklist {
  height: 100%;
  background-color: #fff;
  .slider_header {
    height: 72px;
    line-height: 72px;
    padding-left: 24px;
    .slider_title {
      color: #222222;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Bold;
      font-weight: 700;
      text-align: LEFT;
      .count {
        display: inline-block;
        margin-left: 4px;
        vertical-align: top;
        color: #aaa;
        font-size: 12px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
      }
    }
  }
  .task_item {
    position: relative;
    height: 98px;
    padding: 24px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    text-align: LEFT;
    .task_top {
      height: 22px;
      .icon:hover {
        background-color: #f0f0f0;
        color: #604bdc;
      }
      .task_title {
        float: left;
        width: 119px;
        font-size: 14px;
        color: #8c8c8c;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .tfinish {
        color: #3c3c3c;
        font-family: PingFang SC, PingFang SC-Bold;
        font-weight: 700;
      }
      .tstop {
        color: #ff2222;
      }
      .task_stat {
        float: left;
        height: 20px;
        width: 44px;
        margin-left: 4px;
        border-radius: 2px;
        font-size: 12px;
        text-align: center;
      }
      .finish {
        display: none;
      }
      .pause {
        width: 32px;
        color: #ffb800;
        background: rgba(255, 184, 0, 0.2);
      }
      .stop {
        width: 32px;
        color: #f5222d;
        background: rgba(245, 34, 45, 0.2);
      }
      .stoping {
        color: #f5222d;
        background: rgba(245, 34, 45, 0.2);
      }
      .queued {
        color: #aaa;
        background: #f0f0f0;
      }
      .working {
        color: #604bdc;
        background: rgba(96, 75, 220, 0.2);
      }
      .task_more {
        float: right;
        width: 16px;
        color: #d8d8d8;
        &:hover {
          background-color: #f0f0f0;
          color: #604bdc;
        }
      }
      .task_btn {
        float: right;
        width: 16px;
        margin-right: 16px;
        color: #d8d8d8;
      }
    }
    .task_bottom {
      height: 20px;
      margin-top: 9px;
      font-size: 13px;
      color: #aaa;
      .task_error {
        float: left;
        width: 93px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .task_time {
        float: left;
        margin-left: 16px;
        width: 90px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    &:hover {
      cursor: pointer;
      .time {
        color: #3c3c3c;
      }
      .model {
        color: #666;
      }
      background-color: #f9f9f9;
    }

    &.selected {
      .time {
        color: #3c3c3c;
      }
      .model {
        color: #666;
      }
      background-color: #f9f9f9;
    }
  }

  .slider_item_box {
    position: relative;
    // height: calc(100vh  - 120px);
    height: calc(100% - 72px);
  }
}

.task_del {
  margin-top: -5px;
  vertical-align: middle;
}
</style>
<style>
.rays-tuner-screen-tool-task-menu > .ant-dropdown-menu {
  width: 84px;
}
.rays-tuner-screen-tool-task-menu .ant-dropdown-menu-item {
  color: #aaa;
}
.rays-tuner-screen-tool-task-menu .ant-dropdown-menu-item:hover {
  color: #ff2222;
}
</style>
